<template>
  <div class="add-repair-record">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">新建保养计划</div>
        <div class="right">
          <button @click="toDevice">
            <img src="@/assets/images/home/return.png" alt="" />
            <span class="f14 c6">返回</span>
          </button>
        </div>
      </el-row>
      <div class="line"></div>
      <div class="form-box">
        <el-form
          label-width="115px"
          :model="ruleForm"
          :status-icon="false"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :hide-required-asterisk="true"
        >
          <!-- 客户: -->
          <el-form-item label="客户:" required prop="mainEngineer">
            <el-input
              type="text"
              maxlength="20"
              v-model="ruleForm.person"
              placeholder="同步带出"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 客户:结束 -->
          <!-- 联系人: -->
          <el-form-item label="联系人:" required prop="person">
            <el-input
              type="text"
              maxlength="20"
              v-model="ruleForm.person"
              placeholder="同步带出"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 联系人:结束 -->
          <!-- 联系方式：: -->
          <el-form-item label="联系方式:" required prop="phone">
            <el-input
              type="phone"
              maxlength="11"
              v-model="ruleForm.phone"
              placeholder="同步带出"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 联系方式：:结束 -->
          <!-- 协助工程师：: -->
          <el-form-item label="选择设备:" prop="workShopName">
            <el-row class="check" type="flex" align="middle">
              <el-select v-model="ruleForm.workShopName" placeholder="选择设备">
                <el-option
                  v-for="item in workShopData"
                  :key="item.status"
                  :label="item.text"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <i style="margin-left: 10px" class="el-icon-plus"></i>
            </el-row>
          </el-form-item>
          <!-- 协助工程师：:结束 -->
          <!-- 维保内容详情:: -->
          <el-form-item class="mb20" label="选择标准:" required>
            <el-button type="primary" plain>选择标准</el-button>
          </el-form-item>
          <!-- 维保内容详情::结束 -->
          <!-- 维保内容详情:: -->
          <el-form-item class="mb20" label="保养部位:" required prop="content">
            <el-input
              type="textarea"
              v-model="ruleForm.content"
              maxlength="300"
              placeholder="请输入"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 维保内容详情::结束 -->
          <!-- 故障现象：:: -->
          <el-form-item class="mb20" label="保养内容:" required prop="fault">
            <el-input
              type="textarea"
              v-model="ruleForm.fault"
              maxlength="300"
              placeholder="请输入"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <!-- 故障现象：::结束 -->
          <!-- 分析与建议：:: -->
          <!-- <el-form-item
            class="mb20"
            label="分析与建议:"
            required
            prop="addvise"
          >
            <el-input
              type="textarea"
              v-model="ruleForm.addvise"
              maxlength="300"
              placeholder="请输入"
              autocomplete="off"
            ></el-input>
          </el-form-item> -->
          <!-- 分析与建议：::结束 -->
          <!-- 分析与建议：:: -->
          <el-form-item class="mb20" label="操作指南：">
            <div class="upload-box">
              <p class="line1 f14 p">
                <!-- <el-upload
                  class="upload-demo"
                  action="https://jsonplaceholder.typicode.com/posts/"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  multiple
                  :limit="3"
                  :on-exceed="handleExceed"
                  :file-list="ruleForm.fileList"
                >
                  <img src="@/assets/images/home/upload.png" alt="" />
                  <span>上传文件</span>
                </el-upload> -->
                <el-upload
                  :action="objData.host"
                  :data="objData"
                  multiple
                  :limit="3"
                  :before-upload="beforeUploadFun"
                  :on-progress="progressFun"
                  :on-remove="handleRemove"
                  :on-success="videoSuccess"
                  :on-change="handleChange"
                  :file-list="fileList"
                >
                  <img
                    v-if="showAddSource"
                    src="@/assets/images/home/upload.png"
                    alt=""
                  />
                  <span v-if="showAddSource">上传文件</span>
                  <!-- <i class="el-icon-plus" v-if="showAddSource"></i>
                  <div slot="trigger" class="add-box" v-if="showAddSource">
                    <i class="el-icon-plus"></i>
                  </div> -->
                </el-upload>
                <!-- <el-dialog
                  :visible.sync="videodialogVisible"
                  :append-to-body="true"
                >
                  <video
                    id="upvideo"
                    width="100%"
                    autoplay
                    height="500"
                    :src="dialogImageUrl"
                    controls
                  ></video>
                </el-dialog> -->
              </p>
              <p class="line2 f12 cr">注：上传文件大小&lt;200m。</p>
            </div>
          </el-form-item>
          <!-- 分析与建议：::结束 -->
          <!-- 客户: -->
          <el-form-item label="计划时间:" required prop="time">
            <el-date-picker
              v-model="ruleForm.time"
              type="date"
              placeholder="计划时间"
            >
            </el-date-picker>
          </el-form-item>
          <!-- 客户:结束 -->
          <!-- 提交开始 -->
          <el-form-item class="btn-box">
            <el-button class="btn cf f16" @click="submitForm('ruleForm')"
              >取消</el-button
            >
            <el-button class="btn btn2 cf f16" @click="submitForm('ruleForm')"
              >确认</el-button
            >
          </el-form-item>
          <!-- 提交结束 -->
        </el-form>
      </div>
    </Layout>
    <tell-us bottom="266px"></tell-us>
    <Footer></Footer>
  </div>
</template>

<script>
let flag = true;
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
import TellUs from "@/components/TellUs.vue";
import { TrackOpTypes } from "vue";
export default {
  name: "YoufujichuangAddRepairrecord",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
    TellUs,
  },
  data() {
    var validateMainEngineer = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入客户"));
      }
      callback();
    };
    var validatePerson = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系人"));
      }
      callback();
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else {
        if (!/^1[3-9]\d{9}$/.test(this.ruleForm.phone)) {
          callback(new Error("手机号格式不正确"));
        }
      }
      callback();
    };
    var validateLessEngineer = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择设备"));
      }
      callback();
    };
    var validateContent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入保养部位"));
      }
      callback();
    };
    var validateFault = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入保养内容"));
      }
      callback();
    };
    var validateAddvise = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入分析与建议"));
      }
      callback();
    };
    var validateFileList = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请上传文件"));
      }
      callback();
    };
    var validateFileList1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请选择计划时间"));
      }
      callback();
    };
    return {
      workShopData: [],
      ruleForm: {
        time: "", //时间
        workShopName: "",
        mainEngineer: "", //主要工程师
        person: "", //联系人
        phone: "",
        lessEngineer: "", //协助工程师
        content: "", //维保详情内容
        fault: "", //故障现象
        addvise: "", //分析与建议
        fileList: [
          {
            name: "food.jpeg",
            url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
          {
            name: "food2.jpeg",
            url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
          },
        ], //文件
      },
      rules: {
        mainEngineer: [
          { validator: validateMainEngineer, trigger: ["blur", "change"] },
        ],
        person: [{ validator: validatePerson, trigger: ["blur", "change"] }],
        phone: [{ validator: validatePhone, trigger: ["blur", "change"] }],
        workShopName: [
          { validator: validateLessEngineer, trigger: ["blur", "change"] },
        ],
        content: [{ validator: validateContent, trigger: ["blur", "change"] }],
        fault: [{ validator: validateFault, trigger: ["blur", "change"] }],
        addvise: [{ validator: validateAddvise, trigger: ["blur", "change"] }],
        fileList: [
          { validator: validateFileList, trigger: ["blur", "change"] },
        ],
        time: [{ validator: validateFileList1, trigger: ["blur", "change"] }],
      },
      id: "", //设备id

      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      upFile: [], //上传的文件

      upFileName: [], //上传文件名字

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏
    };
  },

  mounted() {},
  created() {
    this.id = this.$route.query.id;
  },

  methods: {
    // 图片上传前函数
    beforeUploadFun(file) {
      // console.log("上传前函数333333333333333333333333", file);
      // var typeLis =  ["png", "jpg", "jpeg", "bmp", "gif", "webp"]
      // 将文件名存入文件列表中
      this.upFileName.push(file.name);
      // console.log(this.upFileName, "上传文件名");
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      // if (this.upImg.length) {
      //   // 用户已经不是第一次上传了  判断一定要是图片才可以
      //   // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
      //   var fileType = file.type.split("/")[0];
      //   // let res = imglist.some((item) => {
      //   //   return item == fileType;
      //   // });
      //   // console.log(res, "结果");
      //   if (fileType != "image") {
      //     // 上传的不是图片
      //     // console.log("结果是图片");
      //     this.$message.info("请继续上传图片");
      //     this.fileList.pop();
      //     return false;
      //   }
      // }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });

            // 文件格式
            var filelist = ["word", "pdf", "excel", "docx"];
            let resultFile = filelist.some(function (item) {
              return item == houzhui;
            });

            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
              this.$message({
                message: "请选择文件",
                icon: "none",
              });
              this.fileList.pop();
              this.showAddSource = true;
              this.upVideo = [];
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upImg.push(videaUrl);
              this.$message({
                message: "请选择文件",
                icon: "none",
              });
              this.fileList.pop();
              this.showAddSource = true;
              this.upImg = [];
            }

            // 上传的是文件的处理
            if (resultFile) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upFile.push(videaUrl);
            } else {
              this.fileList.pop();
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }

            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }

            if (this.upFile.length == 3) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    progressFun() {
      // console.log("正在上传");
    },

    //删除图片
    handleRemove(file, fileList) {
      console.log(file.url, "fileurl");
      console.log(this.fileList, "filelist");
      this.fileList.forEach((item, index) => {
        // console.log(item.url);
        if (item.url == file.url) {
          console.log(index);
          this.fileList.splice(index, 1);
          this.upFile = this.upFile.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.upFileName = this.upFileName.filter((item, delIndex) => {
            return delIndex != index;
          });
        }
      });
      this.showAddSource = true;
      // console.log(this.upFile, "删除了upFile");
      // console.log(this.upFileName, "删除了upFileName");
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    // 图片上传方法
    videoSuccess(res, file, fileList) {
      // this.findvideocover(file.url, file);
    },

    // 提交
    submitForm(formName) {
      if (!flag) {
        return;
      }

      // 文件上传参数   filelist
      let filelistStr = this.upFile.map((item) => item).join(",");
      let filelistNameStr = this.upFileName.map((item) => item).join(",");
      // console.log(filelistStr, "文件参数");
      // console.log(filelistNameStr, "文件名参数");

      // return;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.upFile.length == 0) {
            this.$message({
              message: "请上传文件",
              type: "warning",
            });
            return;
          }
          flag = false;
          let paramData = {
            deviceId: Number(this.id),
            id: Number(this.id),
            currentEngineerName: this.ruleForm.mainEngineer, //当前主工程师NAME
            viceEngineerName: this.ruleForm.lessEngineer, //协助工程师name
            engineerMobile: this.ruleForm.phone, //联系方式
            maintenanceContent: this.ruleForm.content, //维保内容
            faultPhenomenon: this.ruleForm.fault, //故障现象
            analysisSuggest: this.ruleForm.addvise, //分析与建议：
            // repairOrderId: this.id, //报修订单ID
            fileUrl: filelistStr,
            fileName: filelistNameStr,
          };
          // console.log(paramData, "paramdata");
          // return;
          // 跳转至维修记录页
          this.$API
            .addRepairRecord(paramData)
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                  duration: 600,
                });
                setTimeout(() => {
                  flag = true;
                  this.$router.back();
                }, 800);
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });
          // alert("submit!");
        } else {
          flag = true;
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回
    toDevice() {
      this.$router.back();
    },
    //选择文件方法
    // handleRemove(file, fileList) {
    //   console.log(file, fileList);
    // },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style lang="scss">
.add-repair-record {
  .el-upload--picture-card {
    // width: 0px;
    // height: 0px;
    // margin: 0px;
    // padding: 0px;
    // opacity: 0px;
    // color: #ffffff;
    border: none;
    background: transparent;
    width: 150px;
    height: 20px;
    position: relative;
    top: -40px;
    left: -30px;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }

  width: 100%;
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  // 表单
  .form-box {
    margin-top: 40px;
    margin-left: 333px;
    // 上传文件
    .upload-box {
      img {
        width: 20px;
        height: 16px;
      }
    }
    //input表单样式
    .el-input__inner {
      width: 240px;
      height: 40px;
    }
    //输入框下边距
    .el-form-item {
      margin-bottom: 20px !important;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 120px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    .btn-box {
      margin-top: 40px;
    }
    //按钮样式
    .btn {
      display: inline-block;
      width: 220px;
      height: 50px;
      background: #bfbfbf;
      box-shadow: 0px 4px 25px 1px rgba(191, 191, 191, 0.2);
      border-radius: 8px 8px 8px 8px;
      // margin-top: 60px;
      // margin-left: 174px;
      color: #ffffff;
    }

    .btn2 {
      background: linear-gradient(90deg, #ed1b23 0%, #f15f64 100%);
    }
  }
}
</style>
